<template>
  <div class="home-v2-logos">
    <!-- @TODO rename home-v2-logos to horizontal logos -->
    <div class="home-v2-logos__text">
      {{ text }}
    </div>
    <ul class="home-v2-logos__list">
      <li v-for="(image, index) in logos" :key="index">
        <img
          v-if="image.image"
          :src="image.image.src"
          :alt="image.image.title"
          :srcset="`${image.retina_image.src} 2x`"
          loading="lazy"
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'PageHorizontalLogos',
  props: {
    text: {
      type: String,
      required: true,
    },
    logos: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
}
</script>
