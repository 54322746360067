<template>
  <div class="saas-page-logos">
    <div class="saas-page-logos__inner">
      <div class="saas-page-logos__title">
        {{ title }}
      </div>
      <ul class="saas-page-logos__images">
        <li v-for="(image, index) in logos" :key="index">
          <img
            :src="image.max_120_64.src"
            :alt="image.max_120_64.alt"
            :srcset="`${image.max_240_128.src} 2x`"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageLogos',
  props: {
    title: {
      type: String,
      required: true,
    },
    logos: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
}
</script>
