<template>
  <div class="sass-roadmap-page">
    <div class="saas-page-head">
      <div class="saas-rounded-shape saas-rounded-shape__vertical-left-1"></div>
      <div class="saas-rounded-shape saas-rounded-shape__vertical-left-2"></div>
      <div class="saas-rounded-shape saas-rounded-shape__vertical-left-3"></div>
      <div
        class="saas-rounded-shape saas-rounded-shape__vertical-right-1"
      ></div>
      <div
        class="saas-rounded-shape saas-rounded-shape__vertical-right-2"
      ></div>
      <div
        class="saas-rounded-shape saas-rounded-shape__vertical-right-3"
      ></div>
      <h1 class="saas-page-head__title" v-html="formattedTitle"></h1>
      <SaaSSegmentControl
        :segments="segments"
        :selected-segment-index="selectedSegmentIndex"
        @select-segment="handleSelectedSegment"
      ></SaaSSegmentControl>
    </div>

    <div class="sass-roadmap-page__wrapper">
      <RoadmapTimeline :features="segments[selectedSegmentIndex].features" />
      <div class="saas-cta-box saas-cta-box--center">
        <div>
          <span class="saas-cta-box__title">{{ page.footer_name }}</span>
          <p class="saas-cta-box__text">
            {{ page.footer_text }}
          </p>
        </div>
        <div class="saas-cta-box__buttons">
          <CmsLink
            v-for="button in page.footer_buttons"
            :key="button.id"
            :url-or-path="button.value.url_or_path"
            :class="`button button--${button.value.type} button--large`"
          >
            {{ button.value.text }}
          </CmsLink>
        </div>
      </div>
    </div>
    <FooterJoin></FooterJoin>
  </div>
</template>

<script>
import MarkdownIt from 'markdown-it'

import SaaSSegmentControl from '@saas/components/SaaSSegmentControl'
import RoadmapTimeline from '@saas/components/RoadmapTimeline'
import CmsLink from '@saas/components/cms/CmsLink'
import FooterJoin from '@saas/components/footer/FooterJoin'

export default {
  components: { SaaSSegmentControl, RoadmapTimeline, CmsLink, FooterJoin },
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedSegmentIndex: 0,
    }
  },
  computed: {
    formattedTitle() {
      const md = MarkdownIt({ html: false, breaks: false })
      return md.renderInline(this.page.intro_title)
    },
    segments() {
      return this.page.categories.map((category) => {
        return {
          name: category.value.name,
          features: category.value.features,
        }
      })
    },
  },

  mounted() {},
  methods: {
    handleSelectedSegment(index) {
      this.selectedSegmentIndex = index
    },
  },
}
</script>
