<template>
  <div class="saas-section">
    <div v-if="title" class="saas-section__title">
      <h2 class="saas-striped-title">{{ title }}</h2>
    </div>
    <p v-if="text" class="saas-section__description">
      {{ text }}
    </p>

    <div class="saas-rounded-shape saas-rounded-shape__vertical-left-1"></div>
    <div class="saas-rounded-shape saas-rounded-shape__vertical-left-2"></div>
    <div class="saas-rounded-shape saas-rounded-shape__vertical-left-3"></div>
    <div class="saas-rounded-shape saas-rounded-shape__vertical-right-1"></div>
    <div class="saas-rounded-shape saas-rounded-shape__vertical-right-2"></div>
    <div class="saas-rounded-shape saas-rounded-shape__vertical-right-3"></div>

    <div class="page-academy__baserow-certified-cards">
      <div
        v-for="(certification, index) in certifications"
        :key="index"
        class="page-academy__baserow-certified-card"
        :class="{
          'page-academy__baserow-certified-card--blue':
            certification.icon === 'one_star',
          'page-academy__baserow-certified-card--green':
            certification.icon === 'two_stars',
          'page-academy__baserow-certified-card--purple':
            certification.icon === 'three_stars',
          'page-academy__baserow-certified-card--yellow':
            certification.icon === 'partner',
        }"
      >
        <div class="page-academy__baserow-certified-card-wrapper">
          <h3 class="page-academy__baserow-certified-card-title">
            {{ certification.title }}
          </h3>
          <p class="page-academy__baserow-certified-card-description">
            {{ certification.description }}
          </p>
        </div>

        <div class="page-academy__baserow-certified-card-badge">
          <img
            v-if="certification.icon === 'one_star'"
            src="@saas/assets/images/academy-badge-certified-fundamentals.svg"
          />
          <img
            v-else-if="certification.icon === 'two_stars'"
            src="@saas/assets/images/academy-badge-certified-advanced.svg"
          />
          <img
            v-else-if="certification.icon === 'three_stars'"
            src="@saas/assets/images/academy-badge-certified-expert.svg"
          />
          <img
            v-else-if="certification.icon === 'partner'"
            src="@saas/assets/images/academy-badge-certified-partner.svg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageCoursesType',
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    certifications: {
      type: Array,
      required: true,
    },
  },
  methods: {},
}
</script>
