<template>
  <div
    class="saas-page-intro"
    :class="{
      'saas-page-intro--image-at-bottom':
        type === 'image_at_bottom' || type === 'image_at_bottom_scaled',
      'saas-page-intro--image-at-right': type === 'image_right',
      'saas-page-intro--background-light-to-dark':
        backgroundGradient === 'light_to_dark',
      'saas-page-intro--background-dark-to-light':
        backgroundGradient === 'dark_to_light',
    }"
  >
    <template v-if="type === 'shape_default'">
      <div class="saas-rounded-shape saas-rounded-shape__default-left-1"></div>
      <div class="saas-rounded-shape saas-rounded-shape__default-left-2"></div>
      <div class="saas-rounded-shape saas-rounded-shape__default-left-3"></div>
      <div class="saas-rounded-shape saas-rounded-shape__default-right-1"></div>
      <div class="saas-rounded-shape saas-rounded-shape__default-right-2"></div>
    </template>
    <template v-if="type === 'shape_vertical'">
      <div class="saas-rounded-shape saas-rounded-shape__vertical-left-1"></div>
      <div class="saas-rounded-shape saas-rounded-shape__vertical-left-2"></div>
      <div class="saas-rounded-shape saas-rounded-shape__vertical-left-3"></div>
      <div
        class="saas-rounded-shape saas-rounded-shape__vertical-right-1"
      ></div>
      <div
        class="saas-rounded-shape saas-rounded-shape__vertical-right-2"
      ></div>
      <div
        class="saas-rounded-shape saas-rounded-shape__vertical-right-3"
      ></div>
    </template>

    <div class="saas-page-intro__container">
      <div class="saas-page-intro__content">
        <div
          v-if="labelText || labelIcon"
          class="saas-page-intro__label-wrapper"
        >
          <div class="saas-page-intro__label">
            <i
              v-if="labelIcon"
              class=""
              :class="`saas-page-intro__label-icon ${labelIcon}`"
            ></i>
            {{ labelText }}
          </div>
        </div>
        <div
          v-if="top === 'baserow_logo'"
          class="saas-page-intro__logo-icon-v2"
        >
          <img
            src="@saas/assets/images/page-intro-logo-baserow.svg"
            alt="Baserow Logo"
          />
        </div>
        <h1
          class="saas-page-intro__title"
          :class="{
            'saas-page-intro__title--wide': wideContent,
          }"
          v-html="formattedTitle"
        ></h1>
        <p
          v-if="text"
          class="saas-page-intro__description"
          :class="{
            'saas-page-intro__description--wide': wideContent,
          }"
        >
          {{ text }}
        </p>
        <PageIconGrid v-if="usps.length > 0" :icons="usps"></PageIconGrid>
        <div v-if="buttons.length > 0" class="saas-page-intro__actions">
          <CmsLink
            v-for="(button, index) in buttons"
            :key="index"
            :url-or-path="button.url_or_path"
            :class="`button button--${button.type} button--large`"
          >
            {{ button.text }}
          </CmsLink>
        </div>
      </div>
      <div
        v-if="type === 'image_right'"
        class="saas-page-intro__image-at-right"
      >
        <img
          v-if="image"
          :src="image.src"
          :alt="image.alt"
          :srcset="srcset"
          class="saas-page-intro__image-at-right-image"
        />
      </div>
    </div>
    <div
      v-if="type === 'image_at_bottom' || type === 'image_at_bottom_scaled'"
      :class="{
        'saas-page-intro__image-at-bottom': type === 'image_at_bottom',
        'saas-page-intro__image-at-bottom-scaled':
          type === 'image_at_bottom_scaled',
      }"
    >
      <img
        v-if="image"
        :src="image.src"
        :alt="image.alt"
        :srcset="srcset"
        :class="{
          'saas-page-intro__image-at-bottom-scaled-image':
            type === 'image_at_bottom_scaled',
          'saas-page-intro__image-at-bottom-image': type === 'image_at_bottom',
        }"
      />
    </div>
  </div>
</template>

<script>
import MarkdownIt from 'markdown-it'
import PageIconGrid from '@saas/components/page/PageIconGrid'
import CmsLink from '@saas/components/cms/CmsLink'

export default {
  name: 'PageIntro',
  components: { PageIconGrid, CmsLink },
  props: {
    //  blank - shape_default - shape_vertical - image_at_bottom - image_at_bottom_scaled - image_right
    type: {
      type: String,
      required: false,
      default: 'blank',
    },
    labelIcon: {
      type: String,
      required: false,
      default: '',
    },
    labelText: {
      type: String,
      required: false,
      default: '',
    },
    top: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    wideContent: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttons: {
      type: Array,
      required: false,
      default: () => [],
    },
    image: {
      validator: (prop) => typeof prop === 'object' || prop === null,
      required: false,
      default: null,
    },
    retinaImage: {
      validator: (prop) => typeof prop === 'object' || prop === null,
      required: false,
      default: null,
    },
    backgroundGradient: {
      type: String,
      required: true,
    },
    usps: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    formattedTitle() {
      const md = MarkdownIt({ html: false, breaks: false })
      return md.renderInline(this.title)
    },
    srcset() {
      let set = ''
      if (this.retinaImage !== null) {
        set += `${this.retinaImage.src} 2x`
      }
      return set
    },
  },
}
</script>
