<template>
  <ul
    class="saas-page-icon-grid"
    :class="`saas-page-icon-grid--${icons.length}-columns`"
  >
    <li
      v-for="(icon, index) in icons"
      :key="index"
      class="saas-page-icon-grid__item"
    >
      <div v-if="icon.icon_class" class="saas-page-icon-grid__item-icon">
        <i :class="icon.icon_class"></i>
      </div>
      <div class="saas-page-icon-grid__item-text">{{ icon.title }}</div>
      <div
        v-if="icon.description"
        class="saas-page-icon-grid__item-description"
        v-html="icon.description"
      ></div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'PageIconGrid',
  props: {
    icons: {
      type: Array,
      required: true,
    },
  },
}
</script>
