var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sass-roadmap-timeline__items"},[_c('div',{ref:"progressBar",staticClass:"sass-roadmap-timeline__progressbar",class:{
      'sass-roadmap-timeline__progressbar--no-transition':
        !_vm.progressBarCSSTransition,
    }}),_vm._v(" "),_vm._l((_vm.features),function(feature,index){return _c('div',{key:index,ref:"feature",refInFor:true,staticClass:"sass-roadmap-timeline__item",class:{
      'sass-roadmap-timeline__item--current': index === _vm.currentFeatureIndex,
    }},[_c('div',{staticClass:"sass-roadmap-timeline__feature"},[_c('span',[_vm._v(_vm._s(feature.name)+"\n        "),(feature.paid === 'enterprise')?_c('Badge',{staticClass:"sass-roadmap-timeline__feature-badge",attrs:{"color":"magenta","bold":""}},[_vm._v("\n          Enterprise")]):_vm._e(),_vm._v(" "),(feature.paid === 'premium')?_c('Badge',{staticClass:"sass-roadmap-timeline__feature-badge",attrs:{"color":"cyan","bold":""}},[_vm._v("\n          Premium")]):_vm._e()],1),_vm._v(" "),(feature.version)?_c('span',{staticClass:"sass-roadmap-timeline__feature-version",class:{
          'sass-roadmap-timeline__feature-version--wip': feature.in_progress,
        }},[_vm._v(_vm._s(feature.version))]):_vm._e()])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }