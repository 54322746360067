<template>
  <div class="home-v2-intro">
    <div class="home-v2-intro__head">
      <nuxt-link
        v-if="latestReleaseBlogPost"
        :to="{
          name: 'blog-post-page',
          params: {
            blogPostSlug: latestReleaseBlogPost.slug,
          },
        }"
        class="home-v2-intro__release"
      >
        <div class="home-v2-intro__release-new">New</div>
        <div class="home-v2-intro__release-text">
          Baserow v{{ latestReleaseBlogPost.release_version }} is now available
        </div>
        <div class="home-v2-intro__release-icon">
          <i class="iconoir-nav-arrow-right"></i>
        </div>
      </nuxt-link>
      <h1 class="home-v2-intro__title">{{ title }}</h1>
      <div class="home-v2-intro__description">
        {{ description }}
      </div>
      <div class="home-v2-intro__actions">
        <CmsLink
          v-for="button in buttons"
          :key="button.id"
          :url-or-path="button.url_or_path"
          :class="`button button--${button.type} button--large`"
        >
          {{ button.text }}
        </CmsLink>
      </div>
      <div class="home-v2-intro__self-host">
        Prefer to self host?
        <nuxt-link
          :to="{
            name: 'dev-docs',
            params: {
              path: 'installation/install-on-cloudron',
            },
          }"
          title="Deploy Baserow to your own Cloudron instance"
          >Cloudron</nuxt-link
        >,
        <nuxt-link
          :to="{
            name: 'dev-docs',
            params: {
              path: 'installation/install-with-docker',
            },
          }"
          title="Install with Docker"
          >Docker</nuxt-link
        >,
        <nuxt-link
          :to="{
            name: 'dev-docs',
            params: {
              path: 'installation/install-on-aws',
            },
          }"
          title="Manually install Baserow on AWS step by step"
          >AWS</nuxt-link
        >, or
        <nuxt-link
          :to="{
            name: 'dev-docs',
            params: {
              path: 'installation/install-with-helm',
            },
          }"
          title="Manually install Baserow with Helm step by step"
          >Helm</nuxt-link
        >
      </div>
    </div>
    <div class="home-v2-intro__screenshots">
      <img
        v-if="image"
        :src="image.image.src"
        :alt="image.title"
        :srcset="`${image.retina_image.src} 2x`"
        class="home-v2-intro__screenshots-image"
      />
    </div>
  </div>
</template>

<script>
import CmsLink from '@saas/components/cms/CmsLink'

export default {
  name: 'PageHomeIntro',
  components: { CmsLink },
  props: {
    title: {
      required: true,
      type: String,
    },
    description: {
      required: true,
      type: String,
    },
    buttons: {
      required: true,
      type: Array,
    },
    image: {
      required: true,
      type: Object,
    },
    latestReleaseBlogPost: {
      required: true,
      type: [Object, null],
    },
  },
}
</script>
