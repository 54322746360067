<template>
  <div class="saas-section saas-section--gray">
    <div v-if="title" class="saas-section__title">
      <h2 class="saas-striped-title">{{ title }}</h2>
    </div>
    <div class="page-faq-block">
      <div
        v-for="(question, index) in questions"
        :key="question.question"
        class="page-faq-block__item"
        :class="{
          'page-faq-block__item--open': open.includes(index),
        }"
        @click="toggleItemCollapse(index)"
      >
        <h4 class="page-faq-block__question">
          {{ question.question }}
        </h4>
        <transition name="fade" mode="out-in">
          <p v-if="open.includes(index)" class="page-faq-block__answer">
            {{ question.answer }}
          </p>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageFAQ',
  props: {
    title: {
      type: String,
      required: true,
    },
    questions: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      open: [],
    }
  },
  methods: {
    toggleItemCollapse(item) {
      const index = this.open.indexOf(item)
      if (index === -1) {
        this.open.push(item)
      } else {
        this.open.splice(index, 1)
      }
    },
  },
}
</script>
