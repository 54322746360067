<template>
  <div class="saas-section saas-section--dark">
    <div class="saas-section__title">
      <h2 class="saas-striped-title saas-striped-title--light">
        {{ title }}
      </h2>
    </div>
    <div class="saas-section__description saas-section__description--light">
      {{ description }}
    </div>
    <div class="home-v2-compare">
      <div class="home-v2-compare__product-wrapper">
        <div class="home-v2-compare__product">
          <div class="home-v2-compare__current">
            <div class="home-v2-compare__current-text">I'm currently using</div>
            <div class="home-v2-compare__current-dropdown">
              <div class="home-v2-compare__dropdown-wrapper">
                <div
                  v-click-outside="outsideUsingDropdown"
                  class="home-v2-compare__dropdown"
                  :class="{
                    'home-v2-compare__dropdown--open': usingDropdownOpen,
                  }"
                >
                  <a
                    class="home-v2-compare__dropdown-selected home-v2-compare__dropdown-selected--thick"
                    @click="usingDropdownOpen = !usingDropdownOpen"
                  >
                    <img
                      class="home-v2-compare__dropdown-selected-image"
                      :src="competitors[selectedCompetitor].logo.icon.src"
                      loading="lazy"
                    />
                    <div class="home-v2-compare__dropdown-selected-text">
                      {{ competitors[selectedCompetitor].name }}
                    </div>
                    <i
                      class="home-v2-compare__dropdown-selected-icon iconoir-nav-arrow-down"
                    ></i>
                  </a>
                  <div class="home-v2-compare__dropdown-items">
                    <a
                      v-for="(competitor, index) in competitors"
                      :key="competitor.id"
                      class="home-v2-compare__dropdown-item"
                      @click="
                        ;[
                          (selectedCompetitor = index),
                          (usingDropdownOpen = false),
                        ]
                      "
                    >
                      <img :src="competitor.logo.icon.src" loading="lazy" />
                      {{ competitor.name }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="home-v2-compare__to">to</div>
          <div class="home-v2-compare__task">
            <div class="home-v2-compare__dropdown-wrapper">
              <div
                v-click-outside="outsideToDropdown"
                class="home-v2-compare__dropdown"
                :class="{ 'home-v2-compare__dropdown--open': toDropdownOpen }"
              >
                <a
                  class="home-v2-compare__dropdown-selected"
                  @click="toDropdownOpen = !toDropdownOpen"
                >
                  <div class="home-v2-compare__dropdown-selected-text">
                    {{ useCases[selectedUseCase].name }}
                  </div>
                  <i
                    class="home-v2-compare__dropdown-selected-icon iconoir-nav-arrow-down"
                  ></i>
                </a>
                <div class="home-v2-compare__dropdown-items">
                  <a
                    v-for="(competitor, index) in useCases"
                    :key="competitor.id"
                    class="home-v2-compare__dropdown-item"
                    @click="
                      ;[(selectedUseCase = index), (toDropdownOpen = false)]
                    "
                    >{{ competitor.name }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="home-v2-compare__separator"></div>
      <div class="home-v2-compare__vs-wrapper">
        <div class="home-v2-compare__vs">
          <div class="home-v2-compare__vs-product">
            <div class="home-v2-compare__vs-product-logo">
              <img
                class="home-v2-compare__vs-product-logo-image"
                src="@baserow/modules/core/static/img/logo-white.svg"
                alt="Baserow logo"
                loading="lazy"
              />
            </div>
            <div class="home-v2-compare__vs-product-usps-wrapper">
              <div
                class="home-v2-compare__vs-product-usps home-v2-compare__vs-product-usps--dark"
              >
                <div
                  v-for="(usp, index) in competitors[selectedCompetitor]
                    .baserow_points"
                  :key="index"
                  class="home-v2-compare__vs-product-usp"
                >
                  <i
                    class="home-v2-compare__vs-product-usp-icon iconoir-check-circle"
                  ></i>
                  {{ usp }}
                </div>
              </div>
            </div>
          </div>
          <div class="home-v2-compare__vs-middle">
            <div class="home-v2-compare__vs-middle-circle-wrapper">
              <div class="home-v2-compare__vs-middle-circle">VS.</div>
            </div>
          </div>
          <div class="home-v2-compare__vs-product">
            <div class="home-v2-compare__vs-product-logo">
              <img
                class="home-v2-compare__vs-product-logo-image"
                :src="competitors[selectedCompetitor].logo.logo.src"
                loading="lazy"
              />
            </div>
            <div class="home-v2-compare__vs-product-usps-wrapper">
              <div class="home-v2-compare__vs-product-usps">
                <div
                  v-for="(usp, index) in competitors[selectedCompetitor]
                    .competitor_points"
                  :key="index"
                  class="home-v2-compare__vs-product-usp"
                >
                  <i
                    class="home-v2-compare__vs-product-usp-icon iconoir-cancel"
                  ></i>
                  {{ usp }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="useCases[selectedUseCase].template"
        class="home-v2-compare__template-container"
      >
        <div class="home-v2-compare__template-wrapper">
          <div class="home-v2-compare__template">
            <div class="home-v2-compare__template-details">
              <div class="home-v2-compare__template-icon-wrapper">
                <div class="home-v2-compare__template-icon">
                  <i :class="useCases[selectedUseCase].template.icon"></i>
                </div>
              </div>
              <div class="home-v2-compare__template-name">
                {{ useCases[selectedUseCase].template.name }}
              </div>
              <div class="home-v2-compare__template-recommended">
                Recommended
              </div>
            </div>
            <div class="home-v2-compare__template-actions">
              <Button
                tag="nuxt-link"
                :to="{
                  name: 'template-detail',
                  params: {
                    slug: useCases[selectedUseCase].template.slug,
                  },
                }"
                size="large"
                >Start with this template</Button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageComparison',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    competitors: {
      type: Array,
      required: true,
    },
    useCases: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      usingDropdownOpen: false,
      toDropdownOpen: false,
      selectedCompetitor: 0,
      selectedUseCase: 0,
    }
  },
  methods: {
    outsideUsingDropdown() {
      this.usingDropdownOpen = false
    },
    outsideToDropdown() {
      this.toDropdownOpen = false
    },
  },
}
</script>
