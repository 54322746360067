<template>
  <div class="saas-page-timeline">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="saas-page-timeline__item"
    >
      <div class="saas-page-timeline__item-year">
        <span>{{ item.year }}</span>
        <div class="saas-page-timeline__item-indicator"></div>
      </div>
      <div class="saas-page-timeline__item-text">{{ item.text }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PageTimeline',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
}
</script>
