<template>
  <div
    class="saas-page-integration"
    :class="{
      'saas-page-integration--reversed':
        integration.direction === 'screenshot_left_content_right',
    }"
  >
    <div class="saas-page-integration__info">
      <div class="saas-page-integration__integration">
        <a
          :href="integration.link_url"
          target="_blank"
          rel="noopener noreferrer"
          class="saas-page-integration__logo"
        >
          <img
            :src="integration.logo.image.src"
            :alt="integration.logo.image.alt"
            :srcset="`${integration.logo.retina_image.src} 2x`"
          />
        </a>
        <div class="saas-page-integration__name-and-description">
          <div class="saas-page-integration__name">{{ integration.name }}</div>
          <div class="saas-page-integration__description">
            {{ integration.description }}
          </div>
        </div>
      </div>
      <div class="saas-page-integration__usps">
        <div
          v-for="(usp, index) in integration.usps"
          :key="index"
          class="saas-page-integration__usp"
        >
          <div class="saas-page-integration__usp-icon">
            <i :class="usp.icon_class"></i>
          </div>
          <div class="saas-page-integration__usp-title">
            {{ usp.title }}
          </div>
          <div class="saas-page-integration__usp-text">
            {{ usp.description }}
          </div>
        </div>
      </div>
    </div>
    <div class="saas-page-integration__screenshot">
      <a :href="integration.link_url" target="_blank" rel="noopener noreferrer">
        <img
          :src="integration.screenshot.image.src"
          :alt="integration.screenshot.image.alt"
          :srcset="`${integration.screenshot.retina_image.src} 2x`"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageIntegration',
  props: {
    integration: {
      type: Object,
      required: true,
    },
  },
}
</script>
