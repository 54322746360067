var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-v2-intro"},[_c('div',{staticClass:"home-v2-intro__head"},[(_vm.latestReleaseBlogPost)?_c('nuxt-link',{staticClass:"home-v2-intro__release",attrs:{"to":{
        name: 'blog-post-page',
        params: {
          blogPostSlug: _vm.latestReleaseBlogPost.slug,
        },
      }}},[_c('div',{staticClass:"home-v2-intro__release-new"},[_vm._v("New")]),_vm._v(" "),_c('div',{staticClass:"home-v2-intro__release-text"},[_vm._v("\n        Baserow v"+_vm._s(_vm.latestReleaseBlogPost.release_version)+" is now available\n      ")]),_vm._v(" "),_c('div',{staticClass:"home-v2-intro__release-icon"},[_c('i',{staticClass:"iconoir-nav-arrow-right"})])]):_vm._e(),_vm._v(" "),_c('h1',{staticClass:"home-v2-intro__title"},[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_c('div',{staticClass:"home-v2-intro__description"},[_vm._v("\n      "+_vm._s(_vm.description)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"home-v2-intro__actions"},_vm._l((_vm.buttons),function(button){return _c('CmsLink',{key:button.id,class:`button button--${button.type} button--large`,attrs:{"url-or-path":button.url_or_path}},[_vm._v("\n        "+_vm._s(button.text)+"\n      ")])}),1),_vm._v(" "),_c('div',{staticClass:"home-v2-intro__self-host"},[_vm._v("\n      Prefer to self host?\n      "),_c('nuxt-link',{attrs:{"to":{
          name: 'dev-docs',
          params: {
            path: 'installation/install-on-cloudron',
          },
        },"title":"Deploy Baserow to your own Cloudron instance"}},[_vm._v("Cloudron")]),_vm._v(",\n      "),_c('nuxt-link',{attrs:{"to":{
          name: 'dev-docs',
          params: {
            path: 'installation/install-with-docker',
          },
        },"title":"Install with Docker"}},[_vm._v("Docker")]),_vm._v(",\n      "),_c('nuxt-link',{attrs:{"to":{
          name: 'dev-docs',
          params: {
            path: 'installation/install-on-aws',
          },
        },"title":"Manually install Baserow on AWS step by step"}},[_vm._v("AWS")]),_vm._v(", or\n      "),_c('nuxt-link',{attrs:{"to":{
          name: 'dev-docs',
          params: {
            path: 'installation/install-with-helm',
          },
        },"title":"Manually install Baserow with Helm step by step"}},[_vm._v("Helm")])],1)],1),_vm._v(" "),_c('div',{staticClass:"home-v2-intro__screenshots"},[(_vm.image)?_c('img',{staticClass:"home-v2-intro__screenshots-image",attrs:{"src":_vm.image.image.src,"alt":_vm.image.title,"srcset":`${_vm.image.retina_image.src} 2x`}}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }