<template>
  <div class="saas-section saas-section--dark-to-light saas-page-photos">
    <div v-if="title" class="saas-section__title">
      <h2 class="saas-striped-title">{{ title }}</h2>
    </div>
    <div
      v-if="text"
      class="saas-section__description saas-section__description--small"
    >
      {{ text }}
    </div>
    <div v-if="photo1" class="saas-page-photos__photo-1">
      <img
        :src="photo1.image.rc"
        :alt="photo1.image.alt"
        :srcset="getSrcSet(photo1)"
      />
    </div>
    <div v-if="photo2" class="saas-page-photos__photo-2">
      <div class="saas-page-photos__photo-2-inner">
        <img
          :src="photo2.image.src"
          :alt="photo2.image.alt"
          :srcset="getSrcSet(photo2)"
        />
      </div>
    </div>
    <div v-if="photo3" class="saas-page-photos__photo-3">
      <img
        :src="photo3.image.src"
        :alt="photo3.image.alt"
        :srcset="getSrcSet(photo3)"
      />
    </div>
    <div v-if="photo4" class="saas-page-photos__photo-4">
      <img
        :src="photo4.image.src"
        :alt="photo4.image.alt"
        :srcset="getSrcSet(photo4)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PagePhotos',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    text: {
      type: String,
      required: true,
      default: '',
    },
    photo1: {
      validator: (prop) => typeof prop === 'object' || prop === null,
      required: false,
      default: null,
    },
    photo2: {
      validator: (prop) => typeof prop === 'object' || prop === null,
      required: false,
      default: null,
    },
    photo3: {
      validator: (prop) => typeof prop === 'object' || prop === null,
      required: false,
      default: null,
    },
    photo4: {
      validator: (prop) => typeof prop === 'object' || prop === null,
      required: false,
      default: null,
    },
  },
  methods: {
    getSrcSet(photo) {
      let set = ''
      if (this.retina_image !== null) {
        set += `${photo.retina_image.src} 2x`
      }
      return set
    },
  },
}
</script>
