<template>
  <div class="home-v2-badges">
    <div class="home-v2-badges__inner">
      <div
        v-for="badge in badges"
        :key="badge.id"
        class="home-v2-badges__badge"
        :class="{
          'home-v2-badges__badge--vertical':
            badge.image.image.height > badge.image.image.width,
        }"
      >
        <a :href="badge.url" target="_blank">
          <img
            v-if="badge.image"
            :src="badge.image.image.src"
            :alt="badge.image.image.alt"
            :srcset="`${badge.image.retina_image.src} 2x`"
            loading="lazy"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageBadges',
  props: {
    badges: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
}
</script>
