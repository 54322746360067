var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-v2-boxes__box",class:{
    'home-v2-boxes__box--large-font-size': _vm.box.large_font_size,
    'home-v2-boxes__box--1-1': _vm.box.width === '1/1',
    'home-v2-boxes__box--2-3': _vm.box.width === '2/3',
    'home-v2-boxes__box--1-2': _vm.box.width === '1/2',
    'home-v2-boxes__box--1-3': _vm.box.width === '1/3',
  }},[_c('h3',{staticClass:"home-v2-boxes__title"},[_vm._v("\n    "+_vm._s(_vm.box.title)+"\n  ")]),_vm._v(" "),(_vm.box.text)?_c('div',{staticClass:"home-v2-boxes__description"},[_vm._v("\n    "+_vm._s(_vm.box.text)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.box.image)?_c('img',{staticClass:"home-v2-boxes__image",attrs:{"src":_vm.box.image.image.src,"alt":_vm.box.image.image.alt,"srcset":`${_vm.box.image.retina_image.src} 2x`,"loading":"lazy"}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }