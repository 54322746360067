<template>
  <div
    class="saas-page-content-image"
    :class="{
      'saas-page-content-image--reversed': reversed,
    }"
  >
    <div class="saas-page-content-image__inner">
      <div class="saas-page-content-image__content">
        <h2 class="saas-page-content-image__title">
          {{ title }}
        </h2>
        <div class="saas-page-content-image__description" v-html="text"></div>
      </div>
      <div class="saas-page-content-image__image">
        <div v-if="template !== null" class="saas-page-content-image__template">
          <div class="saas-page-content-image__template-head">
            <div class="saas-page-content-image__template-icon">
              <i :class="template.icon"></i>
            </div>
            <div class="saas-page-content-image__template-name">
              {{ template.name }}
            </div>
            <div class="saas-page-content-image__template-action">
              <Button
                tag="nuxt-link"
                :to="{
                  name: 'template-detail',
                  params: { slug: template.slug },
                }"
                type="secondary"
                >See more</Button
              >
            </div>
          </div>
          <div
            class="saas-page-content-image__template-image"
            :class="{
              'saas-page-content-image__template-image--padding':
                templateImagePadding,
            }"
          >
            <img
              v-if="image"
              :src="image.src"
              :alt="image.alt"
              :srcset="srcset"
            />
          </div>
        </div>
        <img
          v-else-if="image"
          :src="image.src"
          :alt="image.alt"
          :srcset="srcset"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHorizontalContentImage',
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    image: {
      validator: (prop) => typeof prop === 'object' || prop === null,
      required: false,
      default: null,
    },
    retinaImage: {
      validator: (prop) => typeof prop === 'object' || prop === null,
      required: false,
      default: null,
    },
    reversed: {
      type: Boolean,
      required: false,
      default: false,
    },
    template: {
      validator: (prop) => typeof prop === 'object' || prop === null,
      required: false,
      default: null,
    },
    templateImagePadding: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    srcset() {
      let set = ''
      if (this.retinaImage !== null) {
        set += `${this.retinaImage.src} 2x`
      }
      return set
    },
  },
}
</script>
