<template>
  <div :class="`saas-quotes saas-quotes--${quotes.length}-columns`">
    <div
      v-for="(quote, index) in quotes"
      :key="index"
      class="saas-quotes__quote-wrapper"
    >
      <div class="saas-quotes__quote">
        <div class="saas-quotes__quote-logo">
          <img
            v-if="quote.logo"
            :src="quote.logo.image.src"
            :alt="quote.logo.image.alt"
            :srcset="`${quote.logo.retina_image.src} 2x`"
            class="saas-quotes__quote-logo-image"
          />
        </div>
        <div class="saas-quotes__quote-text">
          {{ quote.quote }}
        </div>
        <div class="saas-quotes__quote-author">
          <div class="saas-quotes__quote-author-image">
            <img
              v-if="quote.author_photo"
              :src="quote.author_photo.image.src"
              :alt="quote.author_photo.image.alt"
              :srcset="`${quote.author_photo.retina_image.src} 2x`"
              loading="lazy"
            />
          </div>
          <div class="saas-quotes__quote-author-description">
            <div class="saas-quotes__quote-author-name">
              {{ quote.author_name }}
            </div>
            <div class="saas-quotes__quote-author-role">
              {{ quote.author_role }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageQuotes',
  props: {
    quotes: {
      type: Array,
      required: true,
    },
  },
}
</script>
