var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"saas-section",class:{
    'saas-section--gray': _vm.background === 'gray',
    'saas-section--dark': _vm.background === 'dark',
    'saas-section--light-to-dark': _vm.background === 'light_to_dark',
    'saas-section--dark-to-light': _vm.background === 'dark_to_light',
  }},[(_vm.title)?_c('div',{staticClass:"saas-section__title"},[_c('h2',{staticClass:"saas-striped-title"},[_vm._v(_vm._s(_vm.title))])]):_vm._e(),_vm._v(" "),(_vm.text)?_c('p',{staticClass:"saas-section__description"},[_vm._v("\n    "+_vm._s(_vm.text)+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"page-academy__course-type-cards"},_vm._l((_vm.courses),function(course,index){return _c('div',{key:index,staticClass:"page-academy__course-type-card",class:{
        'page-academy__course-type-card--blue': course.type === 'blue',
        'page-academy__course-type-card--green': course.type === 'green',
        'page-academy__course-type-card--purple': course.type === 'purple',
      }},[(course.type === 'blue')?_c('img',{attrs:{"src":require("@saas/assets/images/academy-badge-fundamental-course.svg")}}):(course.type === 'green')?_c('img',{attrs:{"src":require("@saas/assets/images/academy-badge-advanced-course.svg")}}):(course.type === 'purple')?_c('img',{attrs:{"src":require("@saas/assets/images/academy-badge-expert-course.svg")}}):_vm._e(),_vm._v(" "),_c('h3',{staticClass:"page-academy__course-type-card-title"},[_vm._v(_vm._s(course.title))]),_vm._v(" "),_c('p',{staticClass:"page-academy__course-type-card-tagline"},[_vm._v("\n        "+_vm._s(course.tagline)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"page-academy__course-type-card-description"},[_c('p',[_vm._v("\n          "+_vm._s(course.description)+"\n        ")])]),_vm._v(" "),(course.button_type === 'begin_course')?_c('Button',{staticClass:"page-academy__course-type-card-button",attrs:{"tag":"a","size":"large","full-width":"","href":course.button_url}},[_vm._v("Begin course")]):(course.button_type === 'coming_soon')?_c('Button',{staticClass:"page-academy__course-type-card-button",attrs:{"size":"large","full-width":"","disabled":""}},[_vm._v("Coming soon")]):_vm._e()],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }