<template>
  <div class="saas-page-icon-container__wrapper">
    <div class="saas-page-icon-container">
      <div
        v-for="(icon, index) in icons"
        :key="index"
        class="saas-page-icon-container__item"
      >
        <div v-if="icon.icon_class" class="saas-page-icon-container__item-icon">
          <i :class="icon.icon_class"></i>
        </div>
        <div class="saas-page-icon-container__item-text">{{ icon.title }}</div>
        <div
          v-if="icon.description"
          class="saas-page-icon-container__item-description"
          v-html="icon.description"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageIconContainer',
  props: {
    icons: {
      type: Array,
      required: true,
    },
  },
}
</script>
