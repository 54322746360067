<template>
  <div class="saas-page-intro saas-page-intro--no-padding-bottom">
    <div class="saas-page-intro__container">
      <div class="saas-page-intro__content">
        <div class="saas-page-intro__logo-icon">
          <img
            src="@baserow/modules/core/static/img/logoOnly.svg"
            alt="Baserow Logo symbol icon"
          />
        </div>
        <h1 class="saas-page-intro__title" v-html="formattedTitle"></h1>
        <p
          v-if="text"
          class="saas-page-intro__description saas-page-intro__description--small"
        >
          {{ text }}
        </p>
      </div>
      <div class="saas-page-intro__stats">
        <div
          v-for="i in [1, 2, 3]"
          :key="i"
          class="saas-page-intro__stat-wrapper"
        >
          <div class="saas-page-intro__stat-placeholder"></div>
        </div>
        <div
          v-for="stat in stats"
          :key="stat.id"
          class="saas-page-intro__stat-wrapper"
        >
          <div class="saas-page-intro__stat">
            <div class="saas-page-intro__stat-value">{{ stat.value }}</div>
            <div
              v-if="stat.description"
              class="saas-page-intro__stat-description"
            >
              {{ stat.description }}
            </div>
            <div
              v-if="stat.icon_class"
              :class="`saas-page-intro__stat-icon saas-page-intro__stat-icon--${stat.icon_color}`"
            >
              <i :class="stat.icon_class"></i>
            </div>
          </div>
        </div>
        <div
          v-for="i in [1, 2, 3]"
          :key="i"
          class="saas-page-intro__stat-wrapper"
        >
          <div class="saas-page-intro__stat-placeholder"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MarkdownIt from 'markdown-it'

export default {
  name: 'PageIntroWithStats',
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    stats: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    formattedTitle() {
      const md = MarkdownIt({ html: false, breaks: false })
      return md.renderInline(this.title)
    },
  },
}
</script>
