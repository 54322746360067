<template>
  <div
    class="saas-section"
    :class="{
      'saas-section--gray': background === 'gray',
      'saas-section--dark': background === 'dark',
      'saas-section--light-to-dark': background === 'light_to_dark',
      'saas-section--dark-to-light': background === 'dark_to_light',
    }"
  >
    <div v-if="title" class="saas-section__title">
      <h2 class="saas-striped-title">{{ title }}</h2>
    </div>
    <p v-if="text" class="saas-section__description">
      {{ text }}
    </p>

    <div class="page-academy__course-type-cards">
      <div
        v-for="(course, index) in courses"
        :key="index"
        class="page-academy__course-type-card"
        :class="{
          'page-academy__course-type-card--blue': course.type === 'blue',
          'page-academy__course-type-card--green': course.type === 'green',
          'page-academy__course-type-card--purple': course.type === 'purple',
        }"
      >
        <img
          v-if="course.type === 'blue'"
          src="@saas/assets/images/academy-badge-fundamental-course.svg"
        />
        <img
          v-else-if="course.type === 'green'"
          src="@saas/assets/images/academy-badge-advanced-course.svg"
        />
        <img
          v-else-if="course.type === 'purple'"
          src="@saas/assets/images/academy-badge-expert-course.svg"
        />
        <h3 class="page-academy__course-type-card-title">{{ course.title }}</h3>
        <p class="page-academy__course-type-card-tagline">
          {{ course.tagline }}
        </p>
        <div class="page-academy__course-type-card-description">
          <p>
            {{ course.description }}
          </p>
        </div>

        <Button
          v-if="course.button_type === 'begin_course'"
          tag="a"
          size="large"
          full-width
          class="page-academy__course-type-card-button"
          :href="course.button_url"
          >Begin course</Button
        >
        <Button
          v-else-if="course.button_type === 'coming_soon'"
          size="large"
          full-width
          class="page-academy__course-type-card-button"
          disabled
          >Coming soon</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageCoursesType',
  props: {
    background: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    courses: {
      type: Array,
      required: true,
    },
  },
}
</script>
