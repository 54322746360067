<template>
  <div
    class="saas-section"
    :class="{
      'saas-section--gray': background === 'gray',
      'saas-section--dark': background === 'dark',
      'saas-section--light-to-dark': background === 'light_to_dark',
      'saas-section--dark-to-light': background === 'dark_to_light',
      'saas-section--no-padding-bottom': imageSize === '1392',
    }"
  >
    <div
      v-if="title"
      class="saas-section__title"
      :class="{ 'saas-section__title--wide': titleWide }"
    >
      <h2
        class="saas-striped-title"
        :class="{ 'saas-striped-title--light': background === 'dark' }"
      >
        {{ title }}
      </h2>
    </div>
    <div
      v-if="text"
      class="saas-section__description"
      :class="{ 'saas-section__description--light': background === 'dark' }"
    >
      {{ text }}
    </div>
    <div v-if="buttons.length > 0" class="saas-section__actions">
      <CmsLink
        v-for="button in buttons"
        :key="button.id"
        :url-or-path="button.url_or_path"
        :class="`button button--${button.type} button--large`"
      >
        {{ button.text }}
      </CmsLink>
    </div>
    <div
      class="saas-section__image"
      :class="{
        'saas-section__image--wide': imageSize === '1392',
        'saas-section__image--small': imageSize === '820',
      }"
    >
      <img
        v-if="image"
        :src="image.src"
        :alt="image.alt"
        :srcset="`${retinaImage.src} 2x`"
        loading="lazy"
      />
    </div>
  </div>
</template>

<script>
import CmsLink from '@saas/components/cms/CmsLink'

export default {
  name: 'PageVerticalContentImage',
  components: { CmsLink },
  props: {
    background: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    titleWide: {
      type: Boolean,
      required: false,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    buttons: {
      type: Array,
      required: false,
      default: () => [],
    },
    image: {
      validator: (prop) => typeof prop === 'object' || prop === null,
      required: false,
      default: null,
    },
    retinaImage: {
      validator: (prop) => typeof prop === 'object' || prop === null,
      required: false,
      default: null,
    },
    imageSize: {
      type: String,
      required: true,
    },
  },
}
</script>
