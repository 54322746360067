export function getBaserowDefaultOpenGraphImage($config) {
  return `${$config.PUBLIC_WEB_FRONTEND_URL}/img/baserow_open_graph.png`
}

export function formatDateLocale(dateString, locale) {
  const d = new Date(dateString)
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  }
  return d.toLocaleDateString(locale, options)
}
