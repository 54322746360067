<template>
  <div class="saas-page-content-blocks__container">
    <div class="saas-page-content-blocks__wrapper">
      <div class="saas-page-content-blocks">
        <div
          v-for="(block, index) in blocks"
          :key="index"
          class="saas-page-content-blocks__block"
        >
          <div
            v-if="block.icon_class"
            class="saas-page-content-blocks__block-icon"
          >
            <i :class="block.icon_class"></i>
          </div>
          <div v-if="block.title" class="saas-page-content-blocks__block-title">
            {{ block.title }}
          </div>
          <div v-if="block.text" class="saas-page-content-blocks__block-text">
            {{ block.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageContentBlocks',
  props: {
    blocks: {
      type: Array,
      required: true,
    },
  },
}
</script>
