var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"saas-section"},[(_vm.title)?_c('div',{staticClass:"saas-section__title"},[_c('h2',{staticClass:"saas-striped-title"},[_vm._v(_vm._s(_vm.title))])]):_vm._e(),_vm._v(" "),(_vm.text)?_c('p',{staticClass:"saas-section__description"},[_vm._v("\n    "+_vm._s(_vm.text)+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"saas-rounded-shape saas-rounded-shape__vertical-left-1"}),_vm._v(" "),_c('div',{staticClass:"saas-rounded-shape saas-rounded-shape__vertical-left-2"}),_vm._v(" "),_c('div',{staticClass:"saas-rounded-shape saas-rounded-shape__vertical-left-3"}),_vm._v(" "),_c('div',{staticClass:"saas-rounded-shape saas-rounded-shape__vertical-right-1"}),_vm._v(" "),_c('div',{staticClass:"saas-rounded-shape saas-rounded-shape__vertical-right-2"}),_vm._v(" "),_c('div',{staticClass:"saas-rounded-shape saas-rounded-shape__vertical-right-3"}),_vm._v(" "),_c('div',{staticClass:"page-academy__baserow-certified-cards"},_vm._l((_vm.certifications),function(certification,index){return _c('div',{key:index,staticClass:"page-academy__baserow-certified-card",class:{
        'page-academy__baserow-certified-card--blue':
          certification.icon === 'one_star',
        'page-academy__baserow-certified-card--green':
          certification.icon === 'two_stars',
        'page-academy__baserow-certified-card--purple':
          certification.icon === 'three_stars',
        'page-academy__baserow-certified-card--yellow':
          certification.icon === 'partner',
      }},[_c('div',{staticClass:"page-academy__baserow-certified-card-wrapper"},[_c('h3',{staticClass:"page-academy__baserow-certified-card-title"},[_vm._v("\n          "+_vm._s(certification.title)+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"page-academy__baserow-certified-card-description"},[_vm._v("\n          "+_vm._s(certification.description)+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"page-academy__baserow-certified-card-badge"},[(certification.icon === 'one_star')?_c('img',{attrs:{"src":require("@saas/assets/images/academy-badge-certified-fundamentals.svg")}}):(certification.icon === 'two_stars')?_c('img',{attrs:{"src":require("@saas/assets/images/academy-badge-certified-advanced.svg")}}):(certification.icon === 'three_stars')?_c('img',{attrs:{"src":require("@saas/assets/images/academy-badge-certified-expert.svg")}}):(certification.icon === 'partner')?_c('img',{attrs:{"src":require("@saas/assets/images/academy-badge-certified-partner.svg")}}):_vm._e()])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }