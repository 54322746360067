var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"saas-section saas-section--dark"},[_c('div',{staticClass:"saas-section__title"},[_c('h2',{staticClass:"saas-striped-title saas-striped-title--light"},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")])]),_vm._v(" "),_c('div',{staticClass:"saas-section__description saas-section__description--light"},[_vm._v("\n    "+_vm._s(_vm.description)+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"home-v2-compare"},[_c('div',{staticClass:"home-v2-compare__product-wrapper"},[_c('div',{staticClass:"home-v2-compare__product"},[_c('div',{staticClass:"home-v2-compare__current"},[_c('div',{staticClass:"home-v2-compare__current-text"},[_vm._v("I'm currently using")]),_vm._v(" "),_c('div',{staticClass:"home-v2-compare__current-dropdown"},[_c('div',{staticClass:"home-v2-compare__dropdown-wrapper"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.outsideUsingDropdown),expression:"outsideUsingDropdown"}],staticClass:"home-v2-compare__dropdown",class:{
                  'home-v2-compare__dropdown--open': _vm.usingDropdownOpen,
                }},[_c('a',{staticClass:"home-v2-compare__dropdown-selected home-v2-compare__dropdown-selected--thick",on:{"click":function($event){_vm.usingDropdownOpen = !_vm.usingDropdownOpen}}},[_c('img',{staticClass:"home-v2-compare__dropdown-selected-image",attrs:{"src":_vm.competitors[_vm.selectedCompetitor].logo.icon.src,"loading":"lazy"}}),_vm._v(" "),_c('div',{staticClass:"home-v2-compare__dropdown-selected-text"},[_vm._v("\n                    "+_vm._s(_vm.competitors[_vm.selectedCompetitor].name)+"\n                  ")]),_vm._v(" "),_c('i',{staticClass:"home-v2-compare__dropdown-selected-icon iconoir-nav-arrow-down"})]),_vm._v(" "),_c('div',{staticClass:"home-v2-compare__dropdown-items"},_vm._l((_vm.competitors),function(competitor,index){return _c('a',{key:competitor.id,staticClass:"home-v2-compare__dropdown-item",on:{"click":function($event){;[
                        (_vm.selectedCompetitor = index),
                        (_vm.usingDropdownOpen = false),
                      ]}}},[_c('img',{attrs:{"src":competitor.logo.icon.src,"loading":"lazy"}}),_vm._v("\n                    "+_vm._s(competitor.name)+"\n                  ")])}),0)])])])]),_vm._v(" "),_c('div',{staticClass:"home-v2-compare__to"},[_vm._v("to")]),_vm._v(" "),_c('div',{staticClass:"home-v2-compare__task"},[_c('div',{staticClass:"home-v2-compare__dropdown-wrapper"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.outsideToDropdown),expression:"outsideToDropdown"}],staticClass:"home-v2-compare__dropdown",class:{ 'home-v2-compare__dropdown--open': _vm.toDropdownOpen }},[_c('a',{staticClass:"home-v2-compare__dropdown-selected",on:{"click":function($event){_vm.toDropdownOpen = !_vm.toDropdownOpen}}},[_c('div',{staticClass:"home-v2-compare__dropdown-selected-text"},[_vm._v("\n                  "+_vm._s(_vm.useCases[_vm.selectedUseCase].name)+"\n                ")]),_vm._v(" "),_c('i',{staticClass:"home-v2-compare__dropdown-selected-icon iconoir-nav-arrow-down"})]),_vm._v(" "),_c('div',{staticClass:"home-v2-compare__dropdown-items"},_vm._l((_vm.useCases),function(competitor,index){return _c('a',{key:competitor.id,staticClass:"home-v2-compare__dropdown-item",on:{"click":function($event){;[(_vm.selectedUseCase = index), (_vm.toDropdownOpen = false)]}}},[_vm._v(_vm._s(competitor.name)+"\n                ")])}),0)])])])])]),_vm._v(" "),_c('div',{staticClass:"home-v2-compare__separator"}),_vm._v(" "),_c('div',{staticClass:"home-v2-compare__vs-wrapper"},[_c('div',{staticClass:"home-v2-compare__vs"},[_c('div',{staticClass:"home-v2-compare__vs-product"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"home-v2-compare__vs-product-usps-wrapper"},[_c('div',{staticClass:"home-v2-compare__vs-product-usps home-v2-compare__vs-product-usps--dark"},_vm._l((_vm.competitors[_vm.selectedCompetitor]
                  .baserow_points),function(usp,index){return _c('div',{key:index,staticClass:"home-v2-compare__vs-product-usp"},[_c('i',{staticClass:"home-v2-compare__vs-product-usp-icon iconoir-check-circle"}),_vm._v("\n                "+_vm._s(usp)+"\n              ")])}),0)])]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('div',{staticClass:"home-v2-compare__vs-product"},[_c('div',{staticClass:"home-v2-compare__vs-product-logo"},[_c('img',{staticClass:"home-v2-compare__vs-product-logo-image",attrs:{"src":_vm.competitors[_vm.selectedCompetitor].logo.logo.src,"loading":"lazy"}})]),_vm._v(" "),_c('div',{staticClass:"home-v2-compare__vs-product-usps-wrapper"},[_c('div',{staticClass:"home-v2-compare__vs-product-usps"},_vm._l((_vm.competitors[_vm.selectedCompetitor]
                  .competitor_points),function(usp,index){return _c('div',{key:index,staticClass:"home-v2-compare__vs-product-usp"},[_c('i',{staticClass:"home-v2-compare__vs-product-usp-icon iconoir-cancel"}),_vm._v("\n                "+_vm._s(usp)+"\n              ")])}),0)])])])]),_vm._v(" "),(_vm.useCases[_vm.selectedUseCase].template)?_c('div',{staticClass:"home-v2-compare__template-container"},[_c('div',{staticClass:"home-v2-compare__template-wrapper"},[_c('div',{staticClass:"home-v2-compare__template"},[_c('div',{staticClass:"home-v2-compare__template-details"},[_c('div',{staticClass:"home-v2-compare__template-icon-wrapper"},[_c('div',{staticClass:"home-v2-compare__template-icon"},[_c('i',{class:_vm.useCases[_vm.selectedUseCase].template.icon})])]),_vm._v(" "),_c('div',{staticClass:"home-v2-compare__template-name"},[_vm._v("\n              "+_vm._s(_vm.useCases[_vm.selectedUseCase].template.name)+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"home-v2-compare__template-recommended"},[_vm._v("\n              Recommended\n            ")])]),_vm._v(" "),_c('div',{staticClass:"home-v2-compare__template-actions"},[_c('Button',{attrs:{"tag":"nuxt-link","to":{
                name: 'template-detail',
                params: {
                  slug: _vm.useCases[_vm.selectedUseCase].template.slug,
                },
              },"size":"large"}},[_vm._v("Start with this template")])],1)])])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-v2-compare__vs-product-logo"},[_c('img',{staticClass:"home-v2-compare__vs-product-logo-image",attrs:{"src":require("@baserow/modules/core/static/img/logo-white.svg"),"alt":"Baserow logo","loading":"lazy"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-v2-compare__vs-middle"},[_c('div',{staticClass:"home-v2-compare__vs-middle-circle-wrapper"},[_c('div',{staticClass:"home-v2-compare__vs-middle-circle"},[_vm._v("VS.")])])])
}]

export { render, staticRenderFns }