<template>
  <div>
    <div
      v-for="(messageRow, messageRowIndex) in messageRows"
      :key="messageRowIndex"
      class="saas-page-horizontal-messages"
      :class="{
        'saas-page-horizontal-messages--margin-top': messageRowIndex === 0,
      }"
    >
      <div
        class="saas-page-horizontal-messages__inner"
        :class="{
          'saas-page-horizontal-messages__inner--push-left':
            messageRowIndex % 2,
        }"
      >
        <div
          v-for="(message, index) in messageRow"
          :key="index"
          class="saas-page-horizontal-messages__message-wrapper"
        >
          <div class="saas-page-horizontal-messages__message">
            <div class="saas-page-horizontal-messages__message-text">
              {{ message.text }}
            </div>
            <div class="saas-page-horizontal-messages__message-author">
              <div class="saas-page-horizontal-messages__message-author-name">
                {{ message.author_name }}
              </div>
              <div class="saas-page-horizontal-messages__message-author-role">
                {{ message.author_role }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHorizontalMessages',
  props: {
    messageRows: {
      type: Array,
      required: true,
    },
  },
}
</script>
