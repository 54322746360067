<template>
  <div class="sass-roadmap-timeline__items">
    <div
      ref="progressBar"
      class="sass-roadmap-timeline__progressbar"
      :class="{
        'sass-roadmap-timeline__progressbar--no-transition':
          !progressBarCSSTransition,
      }"
    ></div>
    <div
      v-for="(feature, index) in features"
      :key="index"
      ref="feature"
      class="sass-roadmap-timeline__item"
      :class="{
        'sass-roadmap-timeline__item--current': index === currentFeatureIndex,
      }"
    >
      <div class="sass-roadmap-timeline__feature">
        <span
          >{{ feature.name }}
          <Badge
            v-if="feature.paid === 'enterprise'"
            class="sass-roadmap-timeline__feature-badge"
            color="magenta"
            bold
          >
            Enterprise</Badge
          >
          <Badge
            v-if="feature.paid === 'premium'"
            class="sass-roadmap-timeline__feature-badge"
            color="cyan"
            bold
          >
            Premium</Badge
          >
        </span>

        <span
          v-if="feature.version"
          class="sass-roadmap-timeline__feature-version"
          :class="{
            'sass-roadmap-timeline__feature-version--wip': feature.in_progress,
          }"
          >{{ feature.version }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Roadmap',
  props: {
    features: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      progressBarCSSTransition: true,
    }
  },
  computed: {
    currentFeatureIndex() {
      const currentIndex = this.features.findLastIndex(
        (feature) => feature.version && !feature.in_progress
      )
      return currentIndex
    },
  },
  watch: {
    features: {
      handler() {
        this.progressBarCSSTransition = false // disable CSS transition before reseting the height
        this.$nextTick(() => {
          this.$refs.progressBar.style.height = 0 // reset the height
          this.progressBarCSSTransition = true
          this.setProgressBarHeight()
        })
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setProgressBarHeight()
    })
  },
  methods: {
    setProgressBarHeight() {
      if (this.features.length === 0) {
        return
      }

      const currentFeatureEl = this.$refs.feature[this.currentFeatureIndex]

      if (currentFeatureEl) {
        const distanceFromParentTop =
          currentFeatureEl.offsetTop + currentFeatureEl.offsetHeight / 2
        this.$refs.progressBar.style.height = `${distanceFromParentTop}px`
      }
    },
  },
}
</script>
