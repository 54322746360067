<template>
  <component
    :is="typeToPageMap[page.meta.type]"
    v-if="Object.keys(typeToPageMap).includes(page.meta.type)"
    :page="page"
  ></component>
  <div v-else>
    <CmsBlocks :blocks="pageContent"></CmsBlocks>
    <FooterJoin
      :hide="page.hide_footer"
      :title="page.footer_title"
      :text="page.footer_text"
      :buttons="page.footer_buttons"
    ></FooterJoin>
  </div>
</template>

<script>
import { getBaserowDefaultOpenGraphImage } from '@saas/utils'
import cmsPage from '@saas/mixins/cmsPage'
import roadmap from '@saas/pages/roadmap'
import homepage from '@saas/pages/homepage'
import CmsBlocks from '@saas/components/cms/CmsBlocks'
import FooterJoin from '@saas/components/footer/FooterJoin'

export default {
  components: {
    FooterJoin,
    CmsBlocks,
  },
  mixins: [cmsPage],
  layout: 'saas',
  async asyncData(context) {
    const { app, error } = context
    try {
      const page = await cmsPage.asyncData(context)
      if (page.page.content) {
        const topBars = page.page.content
          .filter((block) => block.type === 'top_bars')
          .flatMap((block) => block.value.top_bars)
        await app.store.dispatch('header/setTopBars', topBars)
      }
      return page
    } catch (e) {
      return error({
        statusCode: 400,
        message: 'Something went wrong.',
      })
    }
  },
  data() {
    return {
      typeToPageMap: {
        'saas_cms.RoadmapPage': roadmap,
        'saas_cms.HomePage': homepage,
      },
    }
  },
  head() {
    const head = cmsPage.head.call(this)
    // If it's the homepage, we want to add some additional metadata.
    if (this.$route.fullPath === '/') {
      Object.assign(head, {
        meta: [
          {
            name: 'keywords',
            content:
              'database, online database, open source, web application, database api, database application, spreadsheet application, data collaboration, data platform, no-code, airtable, airtable alternative',
          },
          {
            property: 'og:locale',
            content: 'en_us',
          },
          {
            property: 'og:site_name',
            content: 'Baserow',
          },
          {
            property: 'og:title',
            content: 'Baserow - Open source no-code database',
          },
          {
            property: 'og:url',
            content: `${this.$config.PUBLIC_WEB_FRONTEND_URL}/`,
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:image',
            content: getBaserowDefaultOpenGraphImage(this.$config),
          },
          {
            itemprop: 'name',
            content: 'Baserow - Get the most out of your data.',
          },
          {
            itemprop: 'headline',
            content: 'Baserow - Open source no-code database',
          },
          {
            itemprop: 'author',
            content: 'Baserow',
          },
          ...head.meta,
        ],
      })
    }
    return head
  },
  computed: {
    pageContent() {
      return this.page.content.filter((block) => block.type !== 'top_bars')
    },
  },
  async beforeDestroy() {
    await this.$store.dispatch('header/setTopBars', [])
  },
}
</script>
