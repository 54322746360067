<template>
  <div class="sass-segment-control">
    <ul class="sass-segment-control__items">
      <li
        v-for="(segment, index) in segments"
        ref="segmentItem"
        :key="segment.name"
        class="sass-segment-control__item"
        :class="{
          'sass-segment-control__item--active': selectedSegmentIndex === index,
        }"
      >
        <a
          class="sass-segment-control__item-link"
          @click="selectSegment(index)"
        >
          <i
            :class="[
              segment.icon,
              'sass-segment-control__item-icon',
              {
                [segment.activeIconColor]: selectedSegmentIndex === index,
              },
            ]"
          ></i>
          {{ segment.name }}
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'SaaSSegmentControl',
  props: {
    segments: {
      type: Array,
      required: true,
    },
    selectedSegmentIndex: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  methods: {
    selectSegment(index) {
      this.scrollIntoView(index)
      this.$emit('select-segment', index)
    },
    scrollIntoView(index) {
      const el = this.$refs.segmentItem[index]
      if (el) {
        el.parentNode.scrollLeft =
          el.offsetLeft - el.offsetWidth - el.parentNode.offsetLeft
      }
    },
  },
}
</script>
