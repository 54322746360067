<template>
  <div class="saas-page-templates">
    <nuxt-link
      v-for="(template, index) in notNullTemplates"
      :key="index"
      :to="{ name: 'template-detail', params: { slug: template.slug } }"
      class="saas-page-templates__template"
    >
      <div class="saas-page-templates__template-image">
        <img
          :src="placeholderImages[index % placeholderImages.length]"
          :srcset="`${
            placeholderImages2x[index % placeholderImages2x.length]
          } 2x`"
          loading="lazy"
        />
      </div>
      <div class="saas-page-templates__template-details">
        <div class="saas-page-templates__template-icon">
          <i :class="template.icon"></i>
        </div>
        <div class="saas-page-templates__template-name">
          {{ template.name }}
        </div>
      </div>
    </nuxt-link>
  </div>
</template>
<script>
export default {
  name: 'PageTemplates',
  props: {
    templates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      placeholderImages: [
        require('@saas/assets/images/template_placeholder_purple.png'),
        require('@saas/assets/images/template_placeholder_green.png'),
        require('@saas/assets/images/template_placeholder_yellow.png'),
        require('@saas/assets/images/template_placeholder_red.png'),
      ],
      placeholderImages2x: [
        require('@saas/assets/images/template_placeholder_purple@2x.png'),
        require('@saas/assets/images/template_placeholder_green@2x.png'),
        require('@saas/assets/images/template_placeholder_yellow@2x.png'),
        require('@saas/assets/images/template_placeholder_red@2x.png'),
      ],
    }
  },
  computed: {
    notNullTemplates() {
      return this.templates.filter((template) => template !== null)
    },
  },
}
</script>
