var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"saas-section",class:{
    'saas-section--gray': _vm.background === 'gray',
    'saas-section--dark': _vm.background === 'dark',
    'saas-section--light-to-dark': _vm.background === 'light_to_dark',
    'saas-section--dark-to-light': _vm.background === 'dark_to_light',
    'saas-section--no-padding-bottom': _vm.imageSize === '1392',
  }},[(_vm.title)?_c('div',{staticClass:"saas-section__title",class:{ 'saas-section__title--wide': _vm.titleWide }},[_c('h2',{staticClass:"saas-striped-title",class:{ 'saas-striped-title--light': _vm.background === 'dark' }},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")])]):_vm._e(),_vm._v(" "),(_vm.text)?_c('div',{staticClass:"saas-section__description",class:{ 'saas-section__description--light': _vm.background === 'dark' }},[_vm._v("\n    "+_vm._s(_vm.text)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.buttons.length > 0)?_c('div',{staticClass:"saas-section__actions"},_vm._l((_vm.buttons),function(button){return _c('CmsLink',{key:button.id,class:`button button--${button.type} button--large`,attrs:{"url-or-path":button.url_or_path}},[_vm._v("\n      "+_vm._s(button.text)+"\n    ")])}),1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"saas-section__image",class:{
      'saas-section__image--wide': _vm.imageSize === '1392',
      'saas-section__image--small': _vm.imageSize === '820',
    }},[(_vm.image)?_c('img',{attrs:{"src":_vm.image.src,"alt":_vm.image.alt,"srcset":`${_vm.retinaImage.src} 2x`,"loading":"lazy"}}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }