<template>
  <div class="saas-section home-v2-community saas-section--gray">
    <div class="saas-section__title">
      <h2 class="saas-striped-title">
        {{ title }}
      </h2>
      <div class="saas-section__description">
        {{ text }}
      </div>
    </div>
    <div class="saas-section__actions margin-bottom-0">
      <CmsLink
        v-for="button in buttons"
        :key="button.id"
        :url-or-path="button.url_or_path"
        :class="`button button--${button.type} button--large`"
      >
        {{ button.text }}
      </CmsLink>
    </div>
  </div>
</template>

<script>
import CmsLink from '@saas/components/cms/CmsLink'

export default {
  name: 'PageCommunity',
  components: { CmsLink },
  props: {
    background: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    buttons: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
}
</script>
