<template>
  <div v-if="!hide" class="footer-join">
    <div class="footer-join__inner">
      <div class="footer-join__inner-shape-left-1"></div>
      <div class="footer-join__inner-shape-left-2"></div>
      <div class="footer-join__inner-shape-left-3"></div>
      <div class="footer-join__inner-shape-right-1"></div>
      <div class="footer-join__inner-shape-right-2"></div>
      <div class="footer-join__inner-shape-right-3"></div>
      <div class="footer-join__content">
        <div class="footer-join__title" v-html="formattedTitle"></div>
        <div class="footer-join__description">
          {{
            text ||
            'Join thousands of teams and individuals who are creating their best work yet.'
          }}
        </div>
        <div class="footer-join__buttons">
          <CmsLink
            v-for="button in formattedButtons"
            :key="button.id"
            :url-or-path="button.value.url_or_path"
            class="button button--large"
            :class="{
              'button--primary': button.value.type === 'primary',
              'button--ghost': button.value.type === 'ghost',
            }"
          >
            {{ button.value.text }}
          </CmsLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MarkdownIt from 'markdown-it'
import CmsLink from '@saas/components/cms/CmsLink'

export default {
  name: 'FooterJoin',
  components: { CmsLink },
  props: {
    hide: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    buttons: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    formattedTitle() {
      const md = MarkdownIt({ html: false, breaks: false })
      return md.renderInline(
        this.title || 'Your **product** team’s **OKRs**. In one place.'
      )
    },
    formattedButtons() {
      return this.buttons.length > 0
        ? this.buttons
        : [
            {
              id: '1',
              value: {
                type: 'primary',
                text: 'Sign up for free',
                url_or_path: '/signup',
              },
            },
            {
              id: '2',
              value: {
                type: 'ghost',
                text: 'Contact sales',
                url_or_path: '/contact-sales',
              },
            },
          ]
    },
  },
}
</script>
