<template>
  <div v-if="visibleVariation">
    <component
      :is="cmsBlocksComponent"
      :key="featureFlag"
      :blocks="visibleVariation.blocks"
    ></component>
  </div>
</template>

<script>
import CmsBlocks from '@saas/components/cms/CmsBlocks'

export default {
  name: 'CmsABTest',
  props: {
    featureFlagKey: {
      type: String,
      required: true,
    },
    featureFlagVariations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      featureFlag: null,
      // For some reason using `<CmsBlocks>` doesn't work in combination with server
      // side rendering. I wasn't able to figure out why, but this hack seems to fix it.
      cmsBlocksComponent: CmsBlocks,
    }
  },
  computed: {
    visibleVariation() {
      const featureFlag = this.featureFlag
      return this.featureFlagVariations.find((v, index) => {
        return (
          // If the feature flag is not set, we always want to show the first one
          // because we should not leave the page empty in that case.
          (featureFlag === null && index === 0) ||
          (featureFlag !== null && v.variant_key === featureFlag)
        )
      })
    },
  },
  created() {
    // Check if a query param matching the `featureFlagKey` is provided. If so, then
    // we'll forcefully choose that variant without depending on Posthog.
    const params = this.$route.query
    if (Object.prototype.hasOwnProperty.call(params, this.featureFlagKey)) {
      this.featureFlag = params[this.featureFlagKey]
    }
  },
  mounted() {
    // Skip setting the feature flag via Posthog if it has already been set. This is
    // probably through the query params in that case.
    if (this.$posthog && this.featureFlag === null) {
      this.$posthog.onFeatureFlags(() => {
        const value = this.$posthog.getFeatureFlag(this.featureFlagKey)
        this.featureFlag = value
      })
    }
  },
}
</script>
