var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sass-segment-control"},[_c('ul',{staticClass:"sass-segment-control__items"},_vm._l((_vm.segments),function(segment,index){return _c('li',{key:segment.name,ref:"segmentItem",refInFor:true,staticClass:"sass-segment-control__item",class:{
        'sass-segment-control__item--active': _vm.selectedSegmentIndex === index,
      }},[_c('a',{staticClass:"sass-segment-control__item-link",on:{"click":function($event){return _vm.selectSegment(index)}}},[_c('i',{class:[
            segment.icon,
            'sass-segment-control__item-icon',
            {
              [segment.activeIconColor]: _vm.selectedSegmentIndex === index,
            },
          ]}),_vm._v("\n        "+_vm._s(segment.name)+"\n      ")])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }