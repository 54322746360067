import CMSService from '@saas/services/cms'

export default {
  async asyncData({ error, route, app }) {
    const contentType = route.query.content_type
    const previewToken = route.query.preview_token
    try {
      const cmsService = CMSService(app.$client)
      let data
      if (contentType && previewToken) {
        const response = await cmsService.getPreview(contentType, previewToken)
        data = response.data
      } else {
        const response = await cmsService.getPage(route.path.substring(1))
        data = response.data
      }
      return { page: data }
    } catch (e) {
      throw error({
        statusCode: 404,
        message: 'Page could not be found.',
      })
    }
  },
  head() {
    return {
      title: this.page.meta.seo_title,
      meta: [
        {
          name: 'description',
          content: this.page.meta.search_description,
        },
        {
          itemprop: 'description',
          content: this.page.meta.search_description,
        },
        {
          property: 'og:description',
          content: this.page.meta.search_description,
        },
      ],
    }
  },
}
