var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"saas-page-content-image",class:{
    'saas-page-content-image--reversed': _vm.reversed,
  }},[_c('div',{staticClass:"saas-page-content-image__inner"},[_c('div',{staticClass:"saas-page-content-image__content"},[_c('h2',{staticClass:"saas-page-content-image__title"},[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"saas-page-content-image__description",domProps:{"innerHTML":_vm._s(_vm.text)}})]),_vm._v(" "),_c('div',{staticClass:"saas-page-content-image__image"},[(_vm.template !== null)?_c('div',{staticClass:"saas-page-content-image__template"},[_c('div',{staticClass:"saas-page-content-image__template-head"},[_c('div',{staticClass:"saas-page-content-image__template-icon"},[_c('i',{class:_vm.template.icon})]),_vm._v(" "),_c('div',{staticClass:"saas-page-content-image__template-name"},[_vm._v("\n            "+_vm._s(_vm.template.name)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"saas-page-content-image__template-action"},[_c('Button',{attrs:{"tag":"nuxt-link","to":{
                name: 'template-detail',
                params: { slug: _vm.template.slug },
              },"type":"secondary"}},[_vm._v("See more")])],1)]),_vm._v(" "),_c('div',{staticClass:"saas-page-content-image__template-image",class:{
            'saas-page-content-image__template-image--padding':
              _vm.templateImagePadding,
          }},[(_vm.image)?_c('img',{attrs:{"src":_vm.image.src,"alt":_vm.image.alt,"srcset":_vm.srcset}}):_vm._e()])]):(_vm.image)?_c('img',{attrs:{"src":_vm.image.src,"alt":_vm.image.alt,"srcset":_vm.srcset}}):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }