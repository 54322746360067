<template>
  <div
    class="home-v2-boxes__box"
    :class="{
      'home-v2-boxes__box--large-font-size': box.large_font_size,
      'home-v2-boxes__box--1-1': box.width === '1/1',
      'home-v2-boxes__box--2-3': box.width === '2/3',
      'home-v2-boxes__box--1-2': box.width === '1/2',
      'home-v2-boxes__box--1-3': box.width === '1/3',
    }"
  >
    <h3 class="home-v2-boxes__title">
      {{ box.title }}
    </h3>
    <div v-if="box.text" class="home-v2-boxes__description">
      {{ box.text }}
    </div>
    <img
      v-if="box.image"
      :src="box.image.image.src"
      :alt="box.image.image.alt"
      :srcset="`${box.image.retina_image.src} 2x`"
      class="home-v2-boxes__image"
      loading="lazy"
    />
  </div>
</template>

<script>
export default {
  name: 'PageBox',
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
}
</script>
